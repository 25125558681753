import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@captain/../environments/environment';
import { AppRoutingModule } from '@captain/app-routing.module';
import { AppComponent } from '@captain/app.component';
import {
  AnomalyEffects,
  CharityEffects,
  CoreModule,
  DonationEffects,
  ItemEffects,
  JourneysEffects,
  MarketEffects,
  NotificationsEffects,
  PageEffects,
  PartnerEffects,
  PhoneConfigurationEffects,
  ScheduleHistoryEffects,
  StoreEffects,
  TrpEffects,
  TruckEffects,
  UsersEffects,
  XmileEffects,
  ZoneEffects,
  reducerProvider,
  reducerToken,
} from '@core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RsplPaymentModule } from '@payment';
import { RsplApiModule } from '@rspl-api';
import { RsplAuthModule } from '@rspl-auth';
import { RsplMapModule } from '@rspl-map';
import { RsplTableModule } from '@rspl-table';
import { RsplUIModule } from '@rspl-ui';
import { RsplVersionConfig, RsplVersionModule } from '@rspl-version';
import { NgxStripeModule } from 'ngx-stripe';
import { OrganizationEffects } from './../../../../libs/core/src/lib/store/organization/organization.effects';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CoreModule.forRoot(environment),
        AppRoutingModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        NgxStripeModule.forRoot(environment.stripeKey),
        EffectsModule.forRoot([
            PageEffects,
            ZoneEffects,
            DonationEffects,
            CharityEffects,
            UsersEffects,
            StoreEffects,
            PartnerEffects,
            TruckEffects,
            MarketEffects,
            XmileEffects,
            TrpEffects,
            ScheduleHistoryEffects,
            NotificationsEffects,
            JourneysEffects,
            OrganizationEffects,
            PhoneConfigurationEffects,
            ItemEffects,
            AnomalyEffects,
        ]),
        StoreModule.forRoot(reducerToken),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
            connectInZone: true,
        }),
        RsplUIModule.forRoot(),
        RsplMapModule.forRoot(),
        RsplVersionModule.forRoot({
            currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
        } as RsplVersionConfig),
        RsplTableModule.forRoot(),
        RsplApiModule.forRoot(),
        RsplAuthModule.forRoot(),
        RsplPaymentModule.forRoot(environment)], providers: [reducerProvider, { provide: MAT_DATE_LOCALE, useValue: 'en' }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
